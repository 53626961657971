import React, {useState} from 'react'
import './about.css'
import Copyright from '../../components/copyright/Copyright'
import NavBar from '../../components/navBar/NavBar'
import down from '../../assets/images/down.svg'
import { aboutSectionContext } from '../../assets/data/Data'

function About() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const profileText = aboutSectionContext.at(0);
  const statementText = Object.values(aboutSectionContext.at(1));
  const footnote = Object.values(aboutSectionContext.at(2)).map((item) => <li>{item}</li>);
  const biblio = Object.values(aboutSectionContext.at(3)).map((item) => <p>{item}</p>);
  const part1 = statementText.slice(0,2).map((item) => <p>{item}</p>);
  const quote = statementText[2].split(",").map((item) => <p>{item}</p>);
  const part2 = statementText[3];
  const part3 = statementText[4]

  return (
    <div className='pageContainer' id='aboutPage'>
      <NavBar />
      <div className="aboutContainer">
        <div className="profileWrapper">
          {profileText.aboutText}
        </div>
        <div className="statementWrapper">
          <div className="statementTop" onClick={handleClick}>
            {profileText.statementTitle}
            <img src={down} alt='down arrow' className='downArrow' />
          </div>
          {click ?
            <div className='statementBottom'>
              {part1}
              <blockquote className='note'>{quote}</blockquote>
              {part2}
              {part3}<sup>1</sup>...
              <p>
              According to Worringer, the instinct for “thing-it-self” is most powerful<sup>2</sup>; the
              yearning for abstraction comes from man's anxieties of the chaos that surrounds him, which
              brings him to believe that “wrest[ing] objects of the external world out of its natural context,
              out of the unending flux of being, to purify it of all its dependence upon life” will thereby bring
              him relief. A form with “no smells, no noises, no color; no changing from one state to another
              and the uncertainty that  comes with it”<sup>3</sup>. I don’t find this understanding to be as useful in the
              context cesspool we live in today. Inanimate aesthetic (love) as a form of self-activation may
              cost us our humanity, but a socially-contingent livelihood as we know it is no humanity at all!
              (Who cares for the unsalvageable?) The marginalized suffer in hypervisibility and yet the
              persuasion of an unseen, unlivable state of being is at the heart of objectification- this
              contradiction has led us to the subject/object conundrum, ornamentalism has bloomed in its wake,
              and the object is now our flesh. 
              </p>
              <p>
              Color and texture are agents of reality that manage to escape language-and thereby
              definition- all the time<sup>4</sup>. In doing so they inhabit a sort of unreality. Its untenability
              alludes to a subjectivity that does not require a form at all and signals something grander
              and ever-omnipresent. “It [is miraculous]: a miracle is always a sudden transformation of nature…it
              is less a thing than a trace of a movement.” <sup>5</sup>We must conjure sense from rejection, negative meaning,
              association folding into association-where we are "richer and stranger"<sup>6</sup> than we think, and hence
              never pure. I wonder if the answer lies in not fully knowing ourselves- nomadic<sup>7</sup> and forever flexing instead.
              </p>
              <p>
              Trans-fixatiation disturbs the pure and assemblage is an ode to brokenness. Death is the
              dissolution of the boundaries that define existence but life remains through its multitudes. I’m changing
              like the seasons-watch! I’ll even cut off my finger.<sup>8</sup> Let me break down and apart. Let me break down and apart.
              </p>
              <p>
              This is not a feeling. This can be, I think, a conversation.<sup>9</sup>
              </p>
              <hr/>
              <ol className='footnotes'>
                {footnote}
              </ol>
              {biblio}
            </div>
            :
            <></>
          }        
        </div>
      </div>
      <Copyright />
    </div>
  )
}
export default About