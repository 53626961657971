import React from 'react'
//import ImgGrid from '../../components/imgGrid/ImgGrid'
import ImgGallery from '../../components/imgGallery/ImgGallery'
import { sculptureWork } from '../../assets/data/Data'
import Copyright from '../../components/copyright/Copyright'
import NavBar from '../../components/navBar/NavBar'

function Sculpture() {
  return (
    <div className='pageContainer galleryPage'>
      <NavBar />
      <div className="pageHeader">
        <div className='sectionTitle'>In Plain Sight</div>
        <div className="sectionYear">(2022 - present)</div>
        <div className="sectionMatl">Mixed media on cardboard, pulp, found wood</div>
      </div>
      <ImgGallery gallery={sculptureWork} />
      <Copyright />
    </div>
  )
}

export default Sculpture