import React from 'react'
import { miscWork } from '../../assets/data/Data'
import ImgSlider from '../../components/imgSlider/ImgSlider'
import Copyright from '../../components/copyright/Copyright'
import NavBar from '../../components/navBar/NavBar'

function Misc() {
  return (
    <div className="pageContainer galleryPage">
      <NavBar />
      <ImgSlider slides={miscWork} />
      <Copyright />
    </div>
  )
}

export default Misc