import React from 'react'
import { sketchbookWork } from '../../assets/data/Data'
import ImgGallery from '../../components/imgGallery/ImgGallery'
import Copyright from '../../components/copyright/Copyright'
import NavBar from '../../components/navBar/NavBar'

function Sketchbook() {
  return (
    <div className="pageContainer" id='sketchbookPage'>
      <NavBar />
      <ImgGallery  gallery={sketchbookWork}/>
      <Copyright />
    </div>
  )
}

export default Sketchbook