import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import './details.css'
import { drawingSeries } from '../../assets/data/Data'
import ImgGallery from '../../components/imgGallery/ImgGallery'
import NavBar from '../../components/navBar/NavBar'
import Copyright from '../../components/copyright/Copyright'

function Details() {
  let {id} = useParams();
  let [series, setSeries] = useState(null);


  useEffect(() => {
    let series = drawingSeries.find((series) => series.id === id)
    if (series) {
      setSeries(series)
    }
  }, [id])
  


  return (
    <div className='pageContainer galleryPage'>
      <NavBar />
    {series ? (
      <div className="singleSeries">
        <div className="seriesDesc">
          <h1>{series.titleWithoutYear}</h1>
          <h3>{series.year}</h3>
          <h3>{series.matl}</h3>
          <br />
          {series.text.value === "" ? null : <p>{series.text}</p>}
        </div>  
        <ImgGallery gallery={series.imgs} />
        {series.text2.value === "" ? null : <p>{series.text2}</p>}
        <ImgGallery gallery={series.imgs2} />
      </div>
    ) : null}
    <Copyright />
    </div>
  )
}

export default Details