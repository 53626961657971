import insta from '../images/insta.svg'
import email from '../images/email.svg'

import GW1 from '../images/GW1.png'
import GW2 from '../images/GW2.png'
import GW3 from '../images/GW3.png'
import GW4 from '../images/GW4.png'
import GW5 from '../images/GW5.png'
import GW6 from '../images/GW6.png'
import GW7 from '../images/GW7.png'
import GW8 from '../images/GW8.png'
import GW9 from '../images/GW9.png'
import GW10 from '../images/GW10.png'
import GW11 from '../images/GW11.png'

import BS1 from '../images/BT1.jpg'
import BS2 from '../images/BT2.jpg'
import BS3 from '../images/BT3.jpg'
import BS4 from '../images/BT4.jpg'
import BS5 from '../images/BT5.jpg'

import os from '../images/os.jpg'
import osb from '../images/osb.jpg'
import oso from '../images/oso.jpg'

import isit1 from '../images/isit1.jpg'
import isit2 from '../images/isit2.jpg'
import isit3 from '../images/isit3.jpg'
import isit4 from '../images/isit4.jpg'
import isit5 from '../images/isit5.jpg'
import isit6 from '../images/isit6.jpg'
import isit7 from '../images/isit7.jpg'
import skin from '../images/skin.jpg'

import DV from '../images/daytimeVacancies.jpg'
import LT from '../images/leopardTranspirings.png'
import LGC from '../images/lettuceGardenCharm.jpg'
import LOFS from '../images/lightOfFoldingScreen.jpg'
import PF from '../images/potatoFields.jpg'
import R2 from '../images/rendezvous2.png'
import RDV from '../images/rendezvous.png'
import rubies from '../images/rubies.png'
import splinter from '../images/splinter.jpg'
import untitled from '../images/untitled.jpg'
import storm from '../images/storm.png'

import dwellings from '../images/dwellings.jpg'
import ED from '../images/elephantDream.png'
import OD from '../images/observationDrawings.png'
import TL from '../images/theLeftover.jpg'
import ddm from '../images/ddm.jpg'
import red from '../images/backside.png'
import alienField from '../images/field.png'
import donkey from '../images/donkey.png'

import peace from '../images/peace.png'
import underpass from '../images/underpass.png'
import waysideFront from '../images/waysideFront.jpg'
import waysideBack from '../images/waysideBack.jpg'
import waysideDetail from '../images/waysideDetail.jpg'
import gaze from '../images/gaze.png'
import face from '../images/seeing.png'
//import elephant from '../images/elephant.jpg'
import raft from '../images/raft.png'
import driftwood1 from '../images/driftwood1.png'
import driftwood2 from '../images/driftwood2.png'
import machine from '../images/machine.png'
import mountain from '../images/mountain.png'
import scum from '../images/scum.png'
import reflexes from '../images/reflexes.png'
import reflexes2 from '../images/reflexes2.png'
import port from '../images/port.png'
import tower from '../images/tower.png'
import kingdomOfGlass from '../images/kingdomOfGlass.png'
import sailboat from '../images/sailboat.png'
//import severence from '../images/severence.jpg'
import sundown from '../images/sundown.png'
//import dark from '../images/dark.png'

import swim from '../images/swim.jpg'
import divers from '../images/divers.jpg'
import onlooker from '../images/onlooker.jpg'
import rocks from '../images/rocks.jpg'
import washingAshore from '../images/washingAshore.jpg'
import purple from '../images/purple.jpg'
import wtohg from '../images/whereTheOthersHaveGone.jpg'

import dec1 from '../images/dec1.png'
import dec2 from '../images/dec2.png'
import dec3 from '../images/dec3.png'
import dec4 from '../images/dec4.png'
import dec5 from '../images/dec5.png'
import dec8 from '../images/dec8.png'
import dec9 from '../images/dec9.png'
import dec12 from '../images/dec12.png'
import dec13 from '../images/dec13.png'
import dec16 from '../images/dec16.png'
import sb1 from '../images/sb1.jpg'
import sb2 from '../images/sb2.jpg'
import sb3 from '../images/sb3.jpg'
import sb4 from '../images/sb4.jpg'
import sb5 from '../images/sb5.jpg'
import sb6 from '../images/sb6.jpg'
import sb7 from '../images/sb7.jpg'
import sb8 from '../images/sb8.jpg'
import sb9 from '../images/sb9.jpg'
import sb10 from '../images/sb10.jpg'
import sb11 from '../images/sb11.jpg'
import sb12 from '../images/sb12.png'
import sb13 from '../images/sb13.png'
import sb14 from '../images/sb14.jpg'
import sb15 from '../images/sb15.jpg'
import sb16 from '../images/sb16.jpg'

export const NavItems = [
    {
        id: 1,
        title: 'about',
        url:'/about',
        cName: 'navItem',
    },
    {
        id: 2,
        title: 'gallery',
        url:'/gallery',
        cName: 'navItem',
    },
    {
        id: 3,
        title: 'sketchbook',
        url:'/sketchbook',
        cName: 'navItem',
    }
]

export const DropMenuItems = [
    {
        title: 'Sculpture',
        url: '/sculpture',
        cName: 'dropdownLink'
    },
    {
        title: 'Painting',
        url: '/painting',
        cName: 'dropdownLink'
    },
    {
        title: 'Drawing',
        url: '/drawing',
        cName: 'dropdownLink'
    },
    {
        title: 'Misc',
        url: '/misc',
        cName: 'dropdownLink'
    }
]

export const socialIcons = [
    {
        icon: insta,
        alt: 'Instagram',
        url: 'http://www.instagram.com/hey.goma',
        cName: 'socialLinks'
    },
    {
        icon: email,
        alt: 'Email',
        url: 'mailto:ju.choi168@gmail.com',
        cName: 'socialLinks'
    }
]

export const paintingWork = [
    {
        image: DV,
        alt: 'painting piece',
        title: 'Daytime Vacancies',
        matl: '18" x 24" | Oil paint and color pencil on wood | 2022'
    },
    {
        image: splinter,
        alt: 'painting piece',
        title: 'Story of a Splinter',
        matl: '18" x 24" | Oil paint on wood | 2022'
    },
    {
        image: storm,
        alt: 'painting piece',
        title: 'Eye of the Storm',
        matl: 'Oil paint, soft pastel, and thread on leathered fabric | 2022'
    },
    {
        image: LOFS,
        alt: 'painting piece',
        title: 'Light of Folding Screen',
        matl: '45" x 50" | Oil paint, conte, ink, and hanji on paper | 2021'
    },
    {
        image: LGC,
        alt: 'painting piece',
        title: 'Lettuce Garden Charm',
        matl: '18" x 24" | Oil paint and oil pastel on canvas | 2021'
    },
    {
        image: rubies,
        alt: 'painting piece',
        title: 'Rubies in the Sternum, Long Thereafter',
        matl: '17" x 31" | Oil paint, ink, soft pastel, and glitter on paper | 2021'
    },
    {
        image: LT,
        alt: 'painting piece',
        title: 'Leopard Transpirings',
        matl: '14" x 21" | Oil paint, ink, and shellac on paper | 2021'
    },
    {
        image: untitled,
        alt: 'painting piece',
        title: 'Untitled',
        matl: '8" x 11" | Oil paint and oil pastel on canvas | 2020'
    },
    {
        image: PF,
        alt: 'painting piece',
        title: 'Potato Plains',
        matl: '11" x 8" | Oil paint and oil pastel on canvas | 2020'
    },
    {
        image: RDV,
        alt: 'painting piece',
        title: 'Rendezvous',
        matl: '11" x 8" | Oil paint on masonite | 2020'
    },
    {
        image: R2,
        alt: 'painting piece',
        title: 'Rendezvous II',
        matl: '8" x 11" | Oil paint on masonite | 2020'
    },
]

export const drawingWork = [
    {
        image: red,
        alt: 'drawing piece',
        title: "The Backside of an Extinguished Hill",
        matl: '8" x 9" | Oil monoprint and soft pastel on paper | 2021'
    },
    {
        image: alienField,
        alt: 'drawing piece',
        title: 'Alien Field',
        matl: '8" x 9" | Oil monoprint and soft pastel on paper | 2021'
    },
    {
        image: donkey,
        alt: 'drawing piece',
        title: 'Guardian',
        matl: '20" x 20" | Ink, shellac, glitter, and soft pastel on paper | 2021'
    },
    {
        image: ED,
        alt: 'drawing piece',
        title: 'Elephant Dreams',
        matl: 'Acrylic stencil, glitter, and thread on paper | 2021'
    },
    {
        image: dwellings,
        alt: 'drawing piece',
        title: 'Dwellings',
        matl: '9" x 14" | Acrylic and ink on paper | 2020'
    },
    {
        image: ddm,
        alt: 'drawing piece',
        title: 'Figure Drawing',
        matl: 'Conte and soft pastel on paper | 2020'
    },
    {
        image: OD,
        alt: 'drawing piece',
        title: 'Observation Drawings',
        matl: '9" x 7" | Silver point and open flame on paper | 2020'
    },
    {
        image: TL,
        alt: 'drawing piece',
        title: 'The Leftover',
        matl: '9" x 7" | Acrylic and ink on paper | 2020'
    }
]

export const sculptureWork = [
    {
        image: waysideFront,
        title: "Wayside Re-turns (The Donkey's Path)",
        alt: 'sculpture piece',
        matl: '22.5" x 17.5" x 9" | Acrylic, glitter, charcoal, gesso, rope, figurine on cardboard, found wood, OSB | 2024 '
    },
    {
        image: waysideBack,
        title: "Wayside Re-turns (The Donkey's Path)",
        alt: 'sculpture piece',
        matl: '22.5" x 17.5" x 9" | Acrylic, glitter, charcoal, gesso, rope, figurine on cardboard, found wood, OSB | 2024 '
    },
    {
        image: waysideDetail,
        title: "Wayside Re-turns (The Donkey's Path)",
        alt: 'sculpture piece',
        matl: '22.5" x 17.5" x 9" | Acrylic, glitter, charcoal, gesso, rope, figurine on cardboard, found wood, OSB | 2024 '
    },
    {
        image: peace,
        title: 'Peace...ultimately',
        alt: 'sculpture piece',
        matl: '11.5" x 15" | Acrylic, pencil, glitter, screws, cardboard, wood, uv print, engraving on OSB | 2024 '
    },
    {
        image: underpass,
        title: 'Underpass',
        alt: 'sculpture piece',
        matl: '10" x 11.5" | Acrylic pastel, charcoal on paper pulp, found wood | 2024 '
    },
    {
        image: sundown,
        title: 'Sundown',
        alt: 'sculpture piece',
        matl: '6" x 7" | Acrylic, soft & oil pastel on paper pulp and wood | 2024 '
    },
    {
        image: gaze,
        title: "Before the sun's gaze, hands held",
        alt: 'sculpture piece',
        matl: '15.5" x 9.5" | Acrylic, soft & oil pastel, staples on wood | 2024'
    },
    {
        image: face,
        title: '/seeing',
        alt:'sculpture piece',
        matl: '7" x 9" x 2" | Acrylic, soft & oil pastel on paper pulp, wood | 2023'
    },{
        image: kingdomOfGlass,
        title: 'Kingdom of Glass',
        alt: 'sculpture piece',
        matl: '6" x 5" | Acrylic, screws, soft & oil pastel on cardboard, paper pulp and wood | 2023'
    },
    {
        image: machine,
        title: 'Death of a Machine',
        alt: 'sculpture piece',
        matl: '6" x 7.5" | Acrylic, screws, staples, soft & oil pastel on cardboard, paper pulp and wood | 2023'
    },
    {
        image: sailboat,
        title: 'Sailboat',
        alt: 'sculpture piece',
        matl: '7.5" x 7" | Acrylic, oil pastel and staples on found wood | 2023'
    },
    {
        image: tower,
        title: 'Untitled',
        alt: 'sculpture piece',
        matl: '4.5" x 11.5" | Acrylic, soft & oil pastel on paper pulp and wood | 2023'
    },
    {
        image: mountain,
        alt: 'sculpture piece',
        title: 'As it bellies before our eyes',
        matl: '7" x 7" | Acrylic, glitter, soft and oil pastel on hanji, staples, cardboard, paper pulp and foam board | 2023'
    },
    {
        image: scum,
        alt: 'sculpture piece',
        title: "Scum: The dust that falls on modern things (Olalquiga's Kingdom)",
        matl: '5" x 7" | Acrylic, color pencil, soft & oil pastel on hanji, paper pulp and wood | 2023'
    },
    {
        image: reflexes,
        alt: 'sculpture piece',
        title: 'Reflexes of a Vulnerable Earth (After Worster)',
        matl: '7" x 7" | Acrylic, soft and oil pastel on hanji, staples, cardboard and wood | 2023'
    },
    {
        image: reflexes2,
        alt: 'sculpture piece',
        title: 'Reflexes of a Vulnerable Earth II (After Worster)',
        matl: '7" x 7" | Acrylic, soft and oil pastel on hanji, staples, cardboard and wood | 2023'
    },
    {
        image: driftwood1,
        title: 'Untitled',
        alt: 'sculpture piece',
        matl: '18" x 3" | Oil pastel on found wood | 2023'
    },
    {
        image: driftwood2,
        title: 'Untitled',
        alt: 'sculpture piece',
        matl: '17" x 3" | Oil pastel on found wood | 2023'
    },
    {
        image: port,
        alt: 'sculpture piece',
        title: 'Port',
        matl: '7" x 7" | Oil and ink on found wood | 2022'
    },
    {
        image: raft,
        title: "Raft (the folding horizon on a mackerel's back)",
        alt: 'sculpture piece',
        matl: '12" x 18" | Soft pastel, charcoal, and string on cardboard | 2022'
    },
]

export const miscWork = [
    {
        id: 0,
        image: wtohg,
        title: 'Where the others have gone (2024)',
        alt: 'misc work'
    },
    {
        id: 1,
        image: divers,
        title: 'Divers (2023)',
        alt: 'misc work'
    },
    {
        id: 2,
        image: purple,
        title: 'Coral Wallpaper (2023)',
        alt: 'misc work'
    },
    {
        id: 3,
        image: swim,
        title: 'Swimmer (2022)',
        alt: 'misc work'
    },
    {
        id:4,
        image: washingAshore,
        title: 'Washed Ashore (2022)',
        alt: 'misc work'
    },
    {
        id:5,
        image: rocks,
        title: 'Rock Trail (2022)',
        alt: 'misc work'
    },
    {
        id:6,
        image: onlooker,
        title: 'Onlooker (2022)',
        alt: 'misc work'
    }
]
export const drawingSeries = [
    {
        id: 'groundwater-series',
        title: 'Ground(water) (2023)',
        titleWithoutYear: 'Ground(water)',
        cover: GW1,
        alt: 'groundwater piece',
        year: '2023',
        matl: 'Soft pastel and oil paint on arches oil paper',
        text:`Ground(water) is a series of soft pastel on oil paint monotypes
        dedicated to the tough and tender terrain of the Hawaiian coastline. Inspired
        by ahupua'a networks of fresh and brackish ponds, traditional rock-walls by
        the shoreline, and other features that curate accessible water, the drawings
        seek to define resistance in nature as invasive species, urban clearing and
        pollutants become regular sights to behold.`,
        text2: "",
        imgs: [
            {
                image: GW1,
                alt: 'groundwater'
            },
            {
                image: GW2,
                alt: 'groundwater'
            },
            {
                image: GW3,
                alt: 'groundwater'
            },
            {
                image: GW4,
                alt: 'groundwater'
            },
            {
                image: GW5,
                alt: 'groundwater'
            },
            {
                image: GW6,
                alt: 'groundwater'
            },
            {
                image: GW7,
                alt: 'groundwater'
            },
            {
                image: GW8,
                alt: 'groundwater'
            },
            {
                image: GW9,
                alt: 'groundwater'
            },
            {
                image: GW10,
                alt: 'groundwater'
            },
            {
                image: GW11,
                alt: 'groundwater'
            },
        ],
        imgs2:[]
    },
    {
        id: 'in-summer-i-will-tear-series',
        title: "In Summer I'll Tear (2021)",
        titleWithoutYear: "In Summer I'll Tear",
        cover: isit1,
        alt: "in summer i'll tear piece",
        year: '2021',
        matl: 'Ink, crayon, and graphite on hanji',
        text: `I was born in June, just before harvest season. Come wintertime, the garden beds are left
        empty like lying caskets. Suffering from the symptoms of an insistent longing, lies a winded house
        of splintered corners, rubber pipe, and dried peel. The red that I call home finds shelter in the padded
        dirt, quiet and asleep underneath the nipping cold. And as the sun beckons all life underneath it, the red
        eventually comes - first from the dirt, then into me.`,
        text2: 'Frottage trials on neon tissues paper using dried chili peppers.',
        imgs: [
            {
                image: isit1,
                alt: "in summer i'll tear"
            },
            {
                image: isit2,
                alt: "in summer i'll tear"
            },
            {
                image: isit3,
                alt: "in summer i'll tear"
            },
            {
                image: isit4,
                alt: "in summer i'll tear"
            },
            {
                image: isit5,
                alt: "in summer i'll tear"
            }
        ],
        imgs2: [
            {
                image: isit6,
                alt: "in summer i'll tear"
            },
            {
                image: isit7,
                alt: "in summer i'll tear"
            },
            {
                image: skin,
                alt:"in summer i'll tear"
            }
        ]
    },
    {
        id:'oil-spill-series',
        title: 'Oil Spill (2020)',
        titleWithoutYear: 'Oil Spill',
        cover: os,
        alt: 'oil spill piece',
        year: '2020',
        matl:'Ink etching and color pencil on origami, digital collage',
        text:'',
        text2: "",
        imgs: [
            {
                image: os,
                alt: 'oil spill'
            },
            {
                image: osb,
                alt: 'oil spill'
            },
            {
                image: oso,
                alt: 'oil spill'
            }
        ],
        imgs2:[]
    },
    {
        id:'bedtime-tales-series',
        title: 'Bedtime Tales (2020)',
        titleWithoutYear: 'Bedtime Tales',
        cover: BS1,
        alt: 'Bedtime Tales',
        year: '2020',
        matl: 'Ink, acrylic, and soft pastel on paper',
        text: '',
        text2: "",
        imgs: [
            {
                image: BS1,
                alt: 'bedtime tales'
            },
            {
                image: BS2,
                alt: 'bedtime tales'
            },
            {
                image: BS3,
                alt: 'bedtime tales'
            },
            {
                image: BS4,
                alt: 'bedtime tales'
            },
            {
                image: BS5,
                alt: 'bedtime tales'
            }
        ],
        imgs2: []
    }
]

export const sketchbookWork = [
    {
        image: dec1,
        alt: 'sketchbook piece'
    },
    {
        image: dec2,
        alt: 'sketchbook piece'
    },
    {
        image: dec3,
        alt: 'sketchbook piece'
    },
    {
        image: dec4,
        alt: 'sketchbook piece'
    },
    {
        image: dec5,
        alt: 'sketchbook piece'
    },
    {
        image: dec8,
        alt: 'sketchbook piece'
    },
    {
        image: dec9,
        alt: 'sketchbook piece'
    },
    {
        image: dec12,
        alt: 'sketchbook piece'
    },
    {
        image: dec13,
        alt: 'sketchbook piece'
    },
    {
        image: dec16,
        alt: 'sketchbook piece'
    },
    {
        image: sb16,
        alt: 'sketchbook piece'
    },
    {
        image: sb15,
        alt: 'sketchbook piece'
    },
    {
        image: sb14,
        alt: 'sketchbook piece'
    },
    {
        image: sb13,
        alt: 'sketchbook piece'
    },
    {
        image: sb12,
        alt: 'sketchbook piece'
    },
    {
        image: sb11,
        alt: 'sketchbook piece'
    },
    {
        image: sb10,
        alt: 'sketchbook piece'
    },
    {
        image: sb9,
        alt: 'sketchbook piece'
    },
    {
        image: sb8,
        alt: 'sketchbook piece'
    },
    {
        image: sb7,
        alt: 'sketchbook piece'
    },
    {
        image: sb6,
        alt: 'sketchbook piece'
    },
    {
        image: sb5,
        alt: 'sketchbook piece'
    },
    {
        image: sb4,
        alt: 'sketchbook piece'
    },
    {
        image: sb3,
        alt: 'sketchbook piece'
    },
    {
        image: sb2,
        alt: 'sketchbook piece'
    },
    {
        image: sb1,
        alt: 'sketchbook piece'
    }
]

export const aboutSectionContext = [
    {
        aboutText: `June Choi (b. 2001) is a visual artist and a recent graduate from Rhode Island School
        of Design. They predominantly work in painting, drawing, and sculpture, oftentimes
        thinking of ways to merge their applications into one. From an immigrant household
        in the temperate suburbs of Maryland, they developed an affinity for the landscape
        as an image that holds command over our ideas of personhood, origins, and material
        reality. Depictions of enclosed spaces and open skies come together to create
        picturesque scenes that begin to dissolve out of imagination as they erode at their
        edges. In reinvesting in the “natural” as a visual fallacy, they hope to meaningfully
        observe estrangement, idleness, and self-autonomy in an age where the marginalized
        find themselves existing like ghosts in plain sight.`,

        statementTitle: `unable to contain myself: creation about object feeling (May 2024)`,
    },
    {
        par1: `A miserable yawn stretches itself inside from corner to corner.
        I used to lie in bed to this feeling, limp like an old comforter. Sometimes
        I’d imagine myself thinning till as light as a sheet and then blowing up
        bubble - gum style. I despised holding so much air and more than anything I
        wanted to bear the world filtering through me instead. To open my chest and
        bid them all to flood in… the birds, parks, dumps, dams, cans, cars, worms…and
        afterwards, I don’t know what would become of me, except that what was left would
        get to revel in the sounds of a flatulent hollow. This is wishful thinking.`,

        par2: `I am full of contradictions and one of them is that I struggle with spontaneity.
        A year ago, tired of this life of pause, I wrote a note for myself - it reads:`,

        quote: `radicality as embodied by,
        self-reliance (faith),
        change (unforeseen turns),
        and reiteration (this is becoming of ___ and you will witness!)`,

        par3: `In a few short words I had laid out a sequence of to-dos in some dreamful recipe 
        for success, happiness, peace. I dream of doing and I yearn to believe. How to make meaning
        in a world of singularity. How to signal in a sea of symbols. How we may move, pressed
        into the grooves.`,

        par4: `I've been collecting wood scraps for half a year now and routinely sort them into
        bins by potential function (spine, joint, limb, bellybutton); imagining a self between bone,
        plastic, wood, and stone; stingy thingy into little me, me, me`,

        par5: `According to Worringer, the instinct for “thing-it-self” is most powerful; the
        yearning for abstraction comes from man's anxieties of the chaos that surrounds him, which
        brings him to believe that “wrest[ing] objects of the external world out of its natural context,
        out of the unending flux of being, to purify it of all its dependence upon life” will thereby bring
        him relief. A form with “no smells, no noises, no color; no changing from one state to another
        and the uncertainty that  comes with it”. I don’t find this understanding to be as useful in the
        context cesspool we live in today. Inanimate aesthetic (love) as a form of self-activation may
        cost us our humanity, but a socially-contingent livelihood as we know it is no humanity at all!
        (Who cares for the unsalvageable?) The marginalized suffer in hypervisibility and yet the
        persuasion of an unseen, unlivable state of being is at the heart of objectification- this
        contradiction has led us to the subject/object conundrum, ornamentalism has bloomed in its wake,
        and the object is now our flesh. `,

        par6: `Color and texture are agents of reality that manage to escape language-and thereby
        definition- all the time. In doing so they inhabit a sort of unreality. Its untenability
        alludes to a subjectivity that does not require a form at all and signals something grander
        and ever-omnipresent. “It [is miraculous]: a miracle is always a sudden transformation of nature…it
        is less a thing than a trace of a movement.” We must conjure sense from rejection, negative meaning,
        association folding into association-where we are "richer and stranger" than we think, and hence
        never pure. I wonder if the answer lies in not fully knowing ourselves- nomadic and forever flexing instead.`,

        par7: `Trans-fixatiation disturbs the pure and assemblage is an ode to brokenness. Death is the
        dissolution of the boundaries that define existence but life remains through its multitudes. I’m changing
        like the seasons-watch! I’ll even cut off my finger. Let me break down and apart. Let me break down and apart.`,

        par8: `This is not a feeling. This can be, I think, a conversation.`
    },
    {
        fn1: `Cripple and the starfish, line 2`,
        fn2: `Worringer, 18`,
        fn3: `Batchelor, 19`,
        fn4: `Batchelor remarks, "for [him], the rainbow spreads its colour evenly at both edges but has a kink
        in the middle, where yellow meets green...where colour has no name" (93)`,
        fn5: `Barthes on the substance plastic as the embodiment of transformation (97)`,
        fn6: `In Ornamentalism, Cheng draws upon Tony Morrison's Beloved to consider how black flesh and yellow
        ornament converge in racialized objectivity: "Sethe-half woman, half ornament, with her dead back that is also a 
        tree - is a hybrid being whose personhood applies tremendous pressure on our notions of what constitutes
        living versus surviving" (30)`,
        fn7: `"The dynamics of spreading cracks may be an example of what Deleuze and Guattari call the "nomadism"
        of matter. Playing on the notion of metal as a conductor of electricity, they say that metal "conducts"
        (ushers) itself through a series of self-transformations, which is not a sequential movement from one
        fixed point to another, but a tumbling of continuou variations with fuzzy borders" (Bennett, 59)`,
        fn8: `Cripple and the startfish, line 14 - 15`,
        fn9: `Chan, line 14`
    },
    {
        bib1: `Antony and the Johnsons. "Cripple and the Starfish". 2000`,
        bib2: `Batchelor, David. Chromophobia. Reaktion Books, 2000`,
        bib3: `Barthes, Roland. Mythologies. Noonday Press, 1991`,
        bib4: `Bennett, Jane, 1957 "Vibrant Matter: a Political Ecology of Things". Duke University Press, 2010.`,
        bib5: `Chan, Wo. "june 8, the smiley barista remembers my name." Jan 2020. Poetry Foundation`,
        bib6: `Cheng, Anne Anlin. "Ornamentalism: A Feminist Theory for the Yellow Woman." Critical Inquiry vol.44,
         2018, p.415-446`,
        bib7:`Worringer, Wilhelm. Abstration and Empanty: A Contribution to the Psychology of Style. Elephant
        Paperbacks, 1997`
    }
]

/*
{
    image: dark,
    title: 'Untitled',
    alt: 'sculpture piece',
    matl: '7" x 10" | Acrylic, soft & oil pastel, glitter, pastels on cardboard, paper pulp and wood | 2024'
},
 {
        image: elephant,
        title: 'Elephant in the Snake',
        alt: 'sculpture piece',
        matl: '15" x 7" | Cord and nail on found wood | 2023'
    }
*/